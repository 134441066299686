<template>
  <div class="container-fluid">
    <div class="row">
      <nav id="sidebarMenu" class="col-md-3 col-lg-2 d-md-block bg-white sidebar collapse d-sm-none d-md-block d-lg-block">
        <a class="brand-none navbar-brand col-md-3 col-lg-2 me-0 px-3" href="#">
          <img class="img-fluid" :src="require('@/assets/img/brand.png')" style="width:80%;">
        </a>
        <div class="position-sticky pt-3" align="left">
          <ul class="m-p-sidebar nav flex-column mb-auto">
            <li class="nav-item ">
              <router-link to="/dashboard/worker/" class="btn btn-toggle-2 align-items-center rounded  nav-link">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="rgba(13,62,103,1)" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-home" aria-hidden="true">
                  <path d="M3 9l9-7 9 7v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z"></path>
                  <polyline points="9 22 9 12 15 12 15 22"></polyline>
                </svg>Home
              </router-link>
            </li>
            <li class="nav-item ">
              <a class="btn btn-toggle align-items-center rounded collapsed nav-link" data-bs-toggle="collapse" data-bs-target="#activity-collapse" aria-expanded="false" aria-current="page" href="#">
                <i class="fas fa-chart-line margin-r"></i> Activity </a>
              <div class="collapse" id="activity-collapse" style="">
                <ul class="btn-toggle-nav list-unstyled fw-normal pb-1">
                  <li>
                    <router-link to="/dashboard/worker/app" class="dropdown-item rounded">Apps</router-link>
                  </li>
                  <li>
                    <router-link to="/dashboard/worker/url" class="dropdown-item rounded">URLs</router-link>
                  </li>
                   <li>
                    <router-link to="/dashboard/worker/screenshot" class="dropdown-item rounded">Screenshot</router-link>
                  </li>
                </ul>
              </div>
            </li>
            <li class="nav-item">
              <a class="btn btn-toggle align-items-center nav-link rounded collapsed" data-bs-toggle="collapse" data-bs-target="#project-collapse" aria-expanded="false">
                <i class="fas fa-project-diagram margin-r"></i> Projects </a>
              <div class="collapse" id="project-collapse" style="">
                <ul class="btn-toggle-nav list-unstyled fw-normal pb-1 ">
                  <li>
                    <router-link to="/dashboard/worker/project" class="dropdown-item rounded">Project</router-link>
                  </li>
                  <li>
                    <router-link to="/dashboard/worker/todo" class="dropdown-item rounded">To-do</router-link>
                  </li>
                </ul>
              </div>
            </li>
            <li class="nav-item">
              <router-link to="/dashboard/worker/team" class="btn btn-toggle-2 align-items-center rounded  nav-link">
                <i class="fas fa-users margin-r"></i> Team
              </router-link>
            </li>
            <li class="nav-item">
              <router-link to="/dashboard/worker/timesheet" class="btn btn-toggle-2 align-items-center rounded  nav-link">
                <i class="fas fa-stopwatch margin-r"></i> Timesheets
              </router-link>
            </li>
      
            <li class="nav-item">
              <router-link to="/dashboard/worker/report" class="btn btn-toggle-2 align-items-center rounded  nav-link">
                <i class="fas fa-file-alt margin-r"></i> Reports
              </router-link>
            </li>
            
            <li @click="redirect('https://wmappserver.github.io/setup.exe')"  class="nav-item">
              <div class="btn btn-toggle-2 align-items-center rounded  nav-link">
                <i  class="fa fa-windows margin-r"></i> Download for Windows
              </div>
            </li>
    
          </ul>         
          <hr>
          <div class="dropdown m-1">
            <a href="#" class="d-flex align-items-center nav-link text-decoration-none dropdown-toggle" id="dropdownUser2" data-bs-toggle="dropdown" aria-expanded="false">
              <div class='icon-container'>
                <img :src="profileInfo.picture" alt="" width="38" height="38" class="img-avatar">
                <div class='status-circle' v-bind:style="{ backgroundColor: this.UpdateOnlinePost.color}"></div>
              </div>
              <div class="user-side" align="center">              
                <span>{{profileInfo.names}}</span>     
              </div>              
            </a>
            <ul class="dropdown-menu text-small shadow" aria-labelledby="dropdownUser2">
              <li>
                <router-link to="/dashboard/worker/setting/profile" class="dropdown-item">Profile</router-link>
              </li>
              <li>
                <hr class="dropdown-divider">
              </li>
              <li>
                <router-link to="#" class="dropdown-item" v-on:click="Logout">Sign out</router-link>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </div>
  </div>
</template>

<script>
  import Api from '@/services/Request'; 

  export default
  {
    name: 'SideMenu',
    data: () => (
    {
      profileInfo: 
      {
        names:"",
        picture:"",
        AppLink:""
      },
      UpdateOnlinePost: 
      {
        status: "",
        inserted: "",
        color: "#bbb"
      } 
    }),
    computed: 
    {
      Role () 
      {
        //console.log(this.$getRole())
        return (this.$getRole())
      }
    },
    methods: 
    {
      Logout() 
      {
        /* Api.get("/login/logout").then((result) => 
        {
          if(result.data.status == "true")
          {
            this.$store.commit("setAuthentication", false);
            this.$store.commit("setTypeUser", "");
            this.$store.commit("setToken", "");
            this.$store.commit("setRefreshToken", "");
            localStorage.clear();
            this.$router.push({ name: "Home" });
          }
        })
        */
          this.$store.commit("setAuthentication", false);
          this.$store.commit("setTypeUser", "");
          this.$store.commit("setToken", "");
          this.$store.commit("setRefreshToken", "");
          localStorage.clear();
          this.$router.push({ name: "Home" });
      },
      Profile ()
      {
        this.profileInfo.names =  localStorage.getItem('names')
        this.profileInfo.picture = localStorage.getItem('picture')
      },
      UpdateOnline() 
      {
        Api.post("connection/get").then((result) => 
        {
          if(result.data.status == "true")
          {
            this.UpdateOnlinePost.color =  result.data.list.color  
          } 
        })
      },
      redirect (url) 
      {
        window.location = url
      }
    },
    mounted()
    {
      this.Profile()
      this.UpdateOnline()
    }
  }
</script>

<style  scoped></style>
