<template>
  <main class="col-md-9 ms-sm-auto col-lg-10 px-md-4">
    <div class="container" id="custom-cards">
      <div class="row row-cols-1 row-cols-lg-8 align-items-stretch g-4 py-5">
        <div class="col">
          <h3 class="pb-2 border-bottom text-dark-blue">Team of Project</h3>
          <div class="card card-cover overflow-hidden text-white bg-white rounded-5 shadow-lg">
            <div class="d-flex flex-column  p-4 pb-3 text-blue text-shadow-1">
              <!-- Tabla-->
              <div class="table-responsive">
                <table id="team-table" class="table table-striped responsive">
                  <thead>
                    <tr class="text-start">
                      <th scope="col">
                        <span class="text-blue">Team</span>
                      </th>
                    </tr>
                  </thead>
                  <tbody class="mb-special">
                    <tr class="text-start" v-if="ListTeams.status != 'false'" v-for="(obj, key, index) in ListTeams.list" :key="index">
                      <td class="text-start">{{obj.name}}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
  import Api from '@/services/Request';
  import "datatables.net-dt/js/dataTables.dataTables"
  import "datatables.net-dt/css/jquery.dataTables.min.css"
  import $ from 'jquery';
  
  export default 
  {
    name: 'Team',
    data: () => (
    {
      TeamPost: 
      {
        name: "",
        color: "#000000",
        message: "",
        status_post: ""
      },
      ListTeams: 
      {
        status: "false",
        list: {}
      }
    }),
    methods: 
    {
      GetTeam() 
      {
        Api.get("/teams-members/list").then((result) => 
        {
          this.ListTeams.status = result.data.status
          
          if (this.ListTeams.status == "true") 
          {
            this.ListTeams.list = result.data['member-teams'];
            this.$nextTick(() => 
            {
              this.InitDatatable()
            })
          }
        })
      },
      InitDatatable() 
      {
        var table = $('#team-table').DataTable(
        {
          dom: 'lftip',
          //dom: 'Blftip',
          responsive: true,
          destroy: true,
          lengthChange: false,
          aaSorting: [ [0,'asc'] ]
        });
      },
      RefreshDatatable() 
      {
        Api.get("/teams-members/list").then((result) => 
        {
          this.ListTeams.status = result.data.status
          
          if (this.ListTeams.status == "true") 
          {
            this.ListTeams.list = result.data['member-teams'];
          }
        })
      }
    },
    mounted() 
    {
      this.GetTeam()
    }
  }
</script>

<style scoped>
  .table-striped>tbody>tr:nth-of-type(odd) {
    --bs-table-accent-bg: rgb(255 255 255 / 5%);
    color: #424242;
  }

  table.dataTable.no-footer {
    border-bottom: 1px solid #f1f1f1;
  }
</style>
