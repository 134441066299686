<template>
  <header>  
    <div class="navbar navbar-light sticky-top bg-white flex-md-nowrap p-0  d-sm-block d-lg-none d-md-none">   
      <a class="navbar-brand col-md-3 col-lg-2 me-0 px-3" href="#">
        <!--brand-->
        <img class="img-fluid" :src="require('@/assets/img/brand.png')" style="width:70%;">
      </a>
      <button class="navbar-toggler position-absolute d-md-none collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#sidebarMenu" aria-controls="sidebarMenu" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button> 
    </div> 
    <div class="d-none d-sm-block">      
    </div>
  </header>
</template>

<script>
  export default
  {
    name: 'HeaderDash'
  }
</script>

<style scooped></style>
